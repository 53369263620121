export const allMockData = {
  code: 200,
  msg: 'success',
  data: {
    sumRevenue: 24373528.23,
    yesterdayRevenue: 87352.79,
    yesterdayRatio: 10,
    beforeYesterdayRevenue: 79414.8,
    beforeYesterdayRatio: 0,
    byesterdayRevenue: 0,
    sevenDaysRevenue: 551078.88,
    sevenDaysRatio: 9.37,
    sevenDaysRevenue2: 503870.92,
    monthRevenue: 0,
    monthRatio: -100,
    lastMonthRevenue: 2217017.15,
    lastMonthRatio: 11.18,
    blastMonthRevenue: 1994121.03,
    lastMonthRevenue2: 65686.66
  }
}

export const yearMockData = {
  code: 200,
  msg: 'success',
  data: {
    sumRevenue: 8691685.6,
    yesterdayRevenue: 87352.79,
    yesterdayRatio: 10,
    beforeYesterdayRevenue: 79414.8,
    beforeYesterdayRatio: 0,
    byesterdayRevenue: 0,
    sevenDaysRevenue: 551078.88,
    sevenDaysRatio: 9.37,
    sevenDaysRevenue2: 503870.92,
    monthRevenue: 0,
    monthRatio: -100,
    lastMonthRevenue: 2217017.15,
    lastMonthRatio: 11.18,
    blastMonthRevenue: 1994121.03,
    lastMonthRevenue2: 65686.66
  }
}
